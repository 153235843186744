/* OSWALD */
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Oswald';
  src: url('/assets/fonts/oswald/Oswald-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

 /* POPPINS */
 @font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
} @font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
